.o_textarea {
  display: block;
  width: 100%;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  border: 1px solid #e0e0e0;
  overflow: auto;
  resize: vertical;
}

.o_textarea:focus {
  outline: none;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 0px 5px 0px rgba(4, 183, 238, 0.5);
}

.o_actions {
  display: flex;
  justify-content: flex-end;
}
