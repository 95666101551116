/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .o_header {
    width: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .o_header {
    width: 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .o_header {
    width: 50%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .o_header {
    width: 85%;
  }
}
