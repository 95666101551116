.o_main_container {
  background-color: #00000042;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.o_modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  background: #eeeeee;
  border-radius: 1rem;
  padding: 2rem 0;

  .o_close_btn {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 64px;
    height: 64px;
    border: 3px solid #ffffff;
    outline: none;
    border-radius: 50%;
    background-color: #ff0000;

    img {
      width: 75%;
    }
  }

  .o_close_btn:hover {
    background-color: #da0101;
  }
}

.o_qr_container {
  display: flex;
  .o_qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .o_qr_body {
      width: 50%;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .o_modal {
    width: 90%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .o_modal {
    width: 90%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .o_modal {
    width: 75%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .o_modal {
    width: 50%;
  }
}
