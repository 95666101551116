.o_characteristics_card_container {
  text-align: left;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.o_benefits {
  flex-direction: row;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
  }
}
